<template>
  <div class="PlaceOrder">
    <div v-wechat-title='$route.meta.title'></div>
    <van-field readonly v-model="ord_ent" label="订货企业：" placeholder="订货企业,个人信息页切换企业" />
    <van-field readonly clickable label="用户类型：" :value="user_type" placeholder="" @click="checkUserType" />
    <van-field readonly clickable label="订货日期：" :value="date_order" placeholder="" @click="checkShowDate" />
    <van-field readonly clickable label="订货客户：" :value="customer_order" placeholder="请选择订货客户"
      @click="checkShowCustomer" />
    <van-field readonly clickable label="订货品种：" :value="var_order" placeholder="请选择订货品种" @click="checkShowVarieties" />
    <van-field readonly clickable label="运 输 商 ：" :value="shippers" placeholder="请选择运输商" @click="checkShowShippers" />
    <van-field v-model="order_num" type="number" @input="OrdNumInp" label="订货数量：" placeholder="请输入数量(吨)" />
    <van-field name="switch" label="长期有效：">
      <template #input>
        <van-switch v-model="islte" size="30" active-color="#29afa5" @change="changeLte" />
      </template>
    </van-field>
    <!-- 有效日期 -->
    <van-field v-if="!islte" readonly clickable label="有效日期：" :value="eff_date" placeholder="请选择有效日期"
      @click="showEffDate = true" />

    <div class="car-box">
      <div class="car-head">选择车号：</div>
      <div class="car-content">
        <div class="car-list-box">
          <div v-if="carList.length <= 0" style="text-align:center;line-height: 1.2rem;color:#409EFF"
            @click="toChooseCar">点击选择车号</div>
          <div v-else style="padding-bottom: .1rem">
            <div class="car-tag" v-for="item in carList" :key="item.id">{{ item.vehicle }}</div>
          </div>
        </div>
        <div class="car-icon" @click="toChooseCar">
          <van-icon name="arrow" color="#409EFF" />
        </div>
      </div>
    </div>

    <van-button size="large" loading-text="加载中..." :loading="btnLoading" @click="saveOrder">下单</van-button>
    <!-- 选择用户类型 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="onUserCancel" @confirm="onUserConfirm" :default-index='0'
        value-key="roleName" confirm-button-text="确定" />
    </van-popup>
    <!-- 选择订货日期 -->
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker v-model="currentDate" type="date" :formatter="formatter" @confirm="confirmDate"
        @cancel="cancelDate" confirm-button-text="确定" />
    </van-popup>
    <!-- 选择订货客户 -->
    <van-popup v-model="showCustomer" position="bottom">
      <div class="btn_box">
        <div @click="cancelCus">取消</div>
        <div style="color:#29afa5" @click="confirmCus">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_cus" placeholder="输入客商名称进行模糊搜索" :clearable=false />
        <div class="search_btn" @click="searchCusOrd">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in cusOrdList" :key="item.id" @click="chooseCusOrd(item)"
          :class="{ active: item.id === chooseCus.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择订货品种 -->
    <van-popup v-model="showVarieties" position="bottom">
      <div class="btn_box">
        <div @click="cancelMat">取消</div>
        <div style="color:#29afa5" @click="confirmMat">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_var" :clearable=false placeholder="输入产品/物料名称进行模糊搜索" />
        <div class="search_btn" @click="searchMaterial">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in materialList" :key="item.id" @click="chooseMater(item)"
          :class="{ active: item.id === chooseMat.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择运输商 -->
    <van-popup v-model="showShippers" position="bottom">
      <div class="btn_box">
        <div @click="cancelShip">取消</div>
        <div style="color:#29afa5" @click="confirmShip">确定</div>
      </div>
      <div style="display:flex">
        <van-search v-model="search_shippers" :clearable=false placeholder="输入运输商名称进行模糊搜索" />
        <div class="search_btn" @click="searchShipper">搜索</div>
      </div>
      <van-cell-group>
        <van-cell :title="item.name" v-for="item in shippersList" :key="item.id" @click="chooseShipp(item)"
          :class="{ active: item.id === choose_ship.id }" />
      </van-cell-group>
    </van-popup>
    <!-- 选择有效日期 -->
    <van-popup v-model="showEffDate" position="bottom">
      <van-datetime-picker v-model="currentEffDate" type="datetime" :min-date="minDate" :formatter="formatter"
        @confirm="confirmEffDate" @cancel="cancelDEffDate" />
    </van-popup>
  </div>
</template>

<script>
import { log } from 'console'
import wx from 'weixin-js-sdk'
export default {
  name: 'LbPlaceOrderPro',
  data() {
    return {
      // 用户类型的显示与隐藏
      showPicker: false,
      // 订货日期的显示与隐藏
      showDate: false,
      // 订货客户的显示与隐藏
      showCustomer: false,
      // 订货品种的显示与隐藏
      showVarieties: false,
      // 运输商的显示与隐藏
      showShippers: false,
      // 有效日期的显示与隐藏
      showEffDate: false,
      // 輸入框是否只讀
      isReadonly: true,
      // 订货企业
      ord_ent: '',
      // 用户类型
      user_type: '',
      // 订货日期
      date_order: '',
      // 订货客户
      customer_order: '',
      // 用户对应的订货客户列表
      cusOrdList: [],
      // 订货品种
      var_order: '',
      // 订货品种id
      varord_id: '',
      // 是否长期有效
      islte: false,
      // 用户对应的订货品种列表
      materialList: [],
      // 运输商
      shippers: '',
      shippers_id: '',
      // 运输商列表
      shippersList: [],
      // 订货数量
      order_num: '',
      // 有效日期
      eff_date: '',
      // 用户类型数组
      columns: [],
      // 订货日期
      currentDate: new Date(),
      // 有效日期
      currentEffDate: new Date(),
      // 有效日期最小值
      minDate: new Date(),
      // 订户客户的搜索值
      search_cus: '',
      // 订货品种的搜索值
      search_var: '',
      // 运输商的搜索值
      search_shippers: '',
      cus_id: '',
      tenantId: '',
      userId: '',
      token: '',
      // 用户类型
      cusTypes: '',
      // 订货客户id
      clientId: '',
      // 选择的订货客户
      chooseCus: '',
      // 选择的订货品种
      chooseMat: '',
      // 选择的运输商
      choose_ship: '',
      // 下单按钮的状态
      btnLoading: false,
      // 订单状态
      orderType: '3',
      // 销售车辆/材料车
      vehicleType: '',
      // 订单来源 1 pc端 2 小程序
      orderSource: '2',
      // 产品id
      productId: '',
      purveyId: '',
      cloudId: '',
      // 选择的车辆列表
      carList: [],
      orderObj: {}
    }
  },

  destroyed() {
    console.log('销毁')
  },

  activated() {
    this.carList = JSON.parse(sessionStorage.getItem('carList')) || []
    console.log(this.carList)

  },
  created() {
    this.tenantId = localStorage.getItem('tenantId')
    this.cus_id = localStorage.getItem('cid')
    this.token = localStorage.getItem('token')

    // 获取用户信息
    this.getCorp()

    this.date_order = this.util.getDate(new Date().getTime())
    let date=new Date();
    this.eff_date = this.util.TimeFormatYMDhm(new Date(date.setDate(date.getDate()+1)))
    console.log(this.eff_date);
    // this.currentEffDate = new Date(this.currentEffDate.setMonth(this.currentEffDate.getMonth() + 1))

    // console.log(JSON.parse(this.$route.query.obj), 'this.$route.query')
    console.log(this.$route.query.obj, 'this.$route.query')

    if (this.$route.query.obj != undefined) {
      this.orderObj = JSON.parse(this.$route.query.obj) || {}
      console.log(this.orderObj)
      this.user_type = '客 商'
      this.cusTypes = '102'
      this.customer_order = this.orderObj.clientName
      this.clientId = this.orderObj.clientId
      this.var_order = this.orderObj.products[0].specification
      this.varord_id = this.orderObj.products[0].productid
      // this.shippers = this.orderObj.product_name
      console.log(this.date_order)
    } else {
      // 根据当前登录的用户id  获取对应的ymtuserid
      this.getYmtUser(this.cus_id)
      this.orderObj = {}
    }
    // 获取运输商
    const types = '105'
    this.getShippersList(types)
  },

  methods: {
    // 根据当前登录的用户id  获取对应的ymtuserid
    async getYmtUser(tid) {
      const { data: res } = await this.$http.get(`/ymt/ymtuser/getYmtUser/${tid}`)
      console.log(res)
      if (res.code === 0) {
        // 获取当前用户对应的客商
        this.getUserTypeList()
        // this.userId = res.data.data.userId
      }
    },

    // 获取用户信息
    async getCorp() {
      const { data: res } = await this.$http.get(`/admin/tenant/getListByCurUser/${this.cus_id}`)
      console.log(res)
      res.data.forEach(x => {
        if (x.id == this.tenantId) {
          this.ord_ent = x.name
        }
      })
    },

    // 获取用户类型列表
    async getUserTypeList() {
      const { data: res } = await this.$http.get(`/admin/user/getUserType/${this.tenantId}`)
      console.log(res)
      // let types = ''
      if (res.code == 0) {
        if (res.data.length > 0) {
          this.cusTypes = res.data[0].roleCode == 'YMT_CLIENT' ? '102' : res.data[0].roleCode == 'YMT_SUPPLIER' ? '104' : '103'
          const type = this.cusTypes == '102' ? '102' : '104'
          this.getCusOrdList(type, '')
          const meterial = this.cusTypes == '102' ? '101' : '102'
          this.getMateriaList(meterial, '')
          this.columns = res.data
          this.user_type = this.columns[0].roleName
        }
      }
    },

    // 获取当前用户对应的订货客户列表
    async getCusOrdList(type, key) {
      const url = key && key !== '' ? '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + type + '/' + key :
        '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + type
      const { data: res } = await this.$http.get(`${url}`)
      console.log(res)
      if (res.code === 0) {
        this.cusOrdList = res.data || []
        this.customer_order = this.cusOrdList.length <= 0 ? '' : this.cusOrdList[0].name
        console.log(this.cusOrdList)
        this.cusOrdList.forEach(item => {
          if (item.name === this.customer_order) {
            this.chooseCus = item
            console.log(this.chooseCus)
          }
        })

        if (this.cusOrdList !== null && this.cusOrdList.length > 0) {
          this.clientId = this.cusOrdList[0].id
          const meterial = this.cusTypes == '102' ? '101' : '102'
          this.getMateriaList(meterial, '')
        }
      }
    },


    // 订货客户搜索按钮
    searchCusOrd() {
      const types = this.cusTypes == '102' ? '102' : '104'
      const meterial = this.cusTypes == '102' ? '101' : '102'
      this.getMateriaList(meterial, '')
      this.getCusOrdList(types, this.search_cus)
    },

    // 选择订货客户
    chooseCusOrd(val) {
      this.chooseCus = val
    },

    // 确认选择订货客户
    confirmCus() {
      console.log(this.cusTypes)
      this.customer_order = this.chooseCus.name
      this.clientId = this.chooseCus.id
      const material = this.cusTypes == '102' ? '101' : '102'
      this.getMateriaList(material, '')
      this.showCustomer = false
    },

    // 取消选择订货客户
    cancelCus() {
      this.showCustomer = false
    },

    // 获取当前用户对应的订货品种
    async getMateriaList(type, key) {
      const url = key && key !== '' ? '/ymt/material/options/' + '/' + key : '/ymt/material/options/'
      const { data: res } = await this.$http.post(`${url}`, { type: type })
      console.log(res)
      if (res.code === 0) {
        this.materialList = res.data
        this.var_order = this.materialList.length <= 0 ? '' : this.materialList[0].name
        this.varord_id = this.materialList.length <= 0 ? '' : this.materialList[0].id
        this.materialList.forEach(item => {
          if (item.name === this.var_order) {
            this.chooseMat = item
            console.log(this.chooseMat)
          }
        })
      }
    },

    // 订货品种搜索按钮
    searchMaterial() {
      const material = this.cusTypes == '102' ? '101' : '102'
      this.getMateriaList(material, this.search_var)
      // }
    },

    // 选择订货品种
    chooseMater(val) {
      console.log(val)
      this.chooseMat = val
    },

    // 确认选择订货品种
    confirmMat() {
      this.var_order = this.chooseMat.name
      this.varord_id = this.chooseMat.id === undefined ? null : this.chooseMat.id
      this.cloudId = this.chooseMat.cloudId === undefined ? null : this.chooseMat.cloudId
      this.showVarieties = false
    },

    // 取消选择订货品种
    cancelMat() {
      this.showVarieties = false
    },

    // 获取运输商列表
    async getShippersList(type, key) {
      const url = key && key !== '' ? '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + type + '/' + key :
        '/ymt/company/getListForApplet/' + '3' + '/' + this.cus_id + '/' + type
      const { data: res } = await this.$http.get(`${url}`)
      console.log(res)
      if (res.code === 0) {
        this.shippersList = res.data
        this.shippersList.unshift({ name: '暂不选择' })
        this.shippers = this.shippersList.length <= 0 ? '' : this.shippersList[0].name
        this.shippers_id = this.shippersList.length <= 0 ? null : this.shippersList[0].id
        this.shippers_id = this.shippers_id === undefined ? null : this.shippers_id
        this.shippersList.forEach(item => {
          if (item.name === this.shippers) {
            this.choose_ship = item
          }
        })
      }
    },


    // 选择运输商
    chooseShipp(val) {
      this.choose_ship = val
    },

    // 确认选择运输商
    confirmShip() {
      this.shippers = this.choose_ship.name
      this.shippers_id = this.choose_ship.id === undefined ? null : this.choose_ship.id
      this.showShippers = false
    },

    // 取消选择运输商
    cancelShip() {
      this.showShippers = false
    },

    // 运输商搜索框
    searchShipper() {
      const types = '105'
      const key = this.search_shippers
      this.getShippersList(types, key)
    },

    // 选择用户类型
    checkUserType() {
      // console.log(this.orderObj)
      if (JSON.stringify(this.orderObj) == '{}') {
        this.showPicker = true
        console.log(this.showPicker)
      } else {
        this.showPicker = false
      }
    },

    // 确认选择用户类型 
    onUserConfirm(value) {
      this.user_type = value.roleName
      this.showPicker = false
      this.cusTypes = value.roleCode == 'YMT_CLIENT' ? '102' : value.roleCode == 'YMT_SUPPLIER' ? '104' : '103'
      this.mines_id = this.cusTypes == '102' ? this.mines_id : null
      const types = this.cusTypes == '102' ? '102' : '104'
      this.getCusOrdList(types)
      const material = this.cusTypes == '102' ? '101' : '102'
      this.getMateriaList(material, '')
    },

    // 取消选择用户类型
    onUserCancel() {
      this.showPicker = false
    },

    // 订货数量保留两位小数
    OrdNumInp(e) {
      console.log(e)
      if (!(/^[+-]?\d*\.?\d{0,2}$/.test(e))) {
        this.$toast({
          message: '订货数量最多保留两位小数',
          duration: 1000
        })
      }
    },

    changeLte() { },

    toChooseCar() {
      this.$router.push({ path: '/car_list', query: { tid: this.tenantId, token: this.token, cid: this.cus_id } })
    },

    // 日期格式
    formatter(type, val) {
      if (type === 'year') {
        this.value1 = val
        return val + '年'
      }
      if (type === 'month') {
        this.value2 = val
        return val + '月'
      }
      if (type === 'day') {
        this.value3 = val
        return val + '日'
      }
      if (type === 'hour') {
        this.value4 = val
        return val + '时'
      }
      if (type === 'minute') {
        this.value5 = val
        return val + '分'
      }
      return val
    },

    // 确认选择订货日期
    confirmDate(value) {
      console.log(value)
      const selectTime = `${this.value1}年${this.value2}月${this.value3}日`
      console.log('用户选择的日期', new Date(this.currentDate).getTime(), selectTime)
      this.showDate = false
      const time = new Date(this.currentDate).toLocaleDateString().split('/')
      if (Number(time[1]) < 10) { time[1] = '0' + time[1] }
      if (Number(time[2]) < 10) { time[2] = '0' + time[2] }
      this.date_order = time.join('-')
    },

    // 取消选择订货日期
    cancelDate() {
      this.showDate = false
    },

    // 确认选择有效日期
    confirmEffDate() {
      const selectTime = `${this.value1}年${this.value2}月${this.value3}日${this.value4}时${this.value5}分`
      console.log('用户选择的日期', new Date(this.currentDate).getTime(), selectTime)
      this.showEffDate = false
      // const time =new Date(this.currentEffDate) .toLocaleString().split('/')
      this.eff_date = this.util.TimeFormatYMDhm(new Date(this.currentEffDate).getTime())
      // console.log(this.currentEffDate,time);
      // if (Number(time[1]) < 10) { time[1] = '0' + time[1] }
      // if (Number(time[2]) < 10) { time[2] = '0' + time[2] }
      // this.eff_date = time.join('-')
    },

    // 取消选择有效日期
    cancelDEffDate() {
      this.showEffDate = false
    },

    checkShowDate() {
      this.showDate = true
    },

    checkShowCustomer() {
      if (JSON.stringify(this.orderObj) == '{}') {
        this.showCustomer = true
      } else {
        this.showCustomer = false
      }
    },

    checkShowVarieties() {
      if (JSON.stringify(this.orderObj) == '{}') {
        this.showVarieties = true
      } else {
        this.showVarieties = false
      }
    },

    checkShowShippers() {
      // console.log(JSON.stringify(this.orderObj) == '{}')
      // if (JSON.stringify(this.orderObj) == '{}') {
      this.showShippers = true
      // } else {
      //   this.showShippers = false
      // }
    },

    // 下单
    async saveOrder() {
      console.log(this.carList)
      this.btnLoading = true
      console.log(this.cusTypes)
      let warn = ''
      if (this.clientId == '' || this.clientId == null) {
        warn = '请选择订货客户！'
      } else if (this.varord_id === '' || this.varord_id == null) {
        warn = '请选择订货品种！'
      } else if (this.cusTypes == '102' && this.order_num == '') {
        warn = '请输入订货数量！'
      } else if (!this.isCheckReturn && parseFloat(this.order_num) < 0) {
        warn = '订货数量不能为负数！'
      } else if (this.carList.length <= 0) {
        warn = '请选择车号！'
      } else {
        this.save()
      }

      if (warn !== '') {
        this.$dialog({
          title: '提示',
          message: warn,
          confirmButtonColor: '#727AA1',
          showCancel: false
        }).then(() => {
          this.btnLoading = false
        })
      }
    },

    // 下单
    async save() {
      this.btnLoading = true
      // 101 销售车辆 102 材料车
      this.vehicleType = this.cusTypes == '102' ? '101' : this.cusTypes == '104' ? '102' : '103'

      // 是长期有效 有效日期 默认2099-12-31  
      let effDate = this.eff_date
      effDate = this.islte ? '2099-12-31 00:00:00' : this.eff_date + ":00"
      let isLongEffective = this.islte ? '1' : '0'

      // const carList = []

      // this.carList.forEach(x => {
      //   carList.push(x.vehicle)
      // })
      // 如果没有输入订货数量 默认为0
      const ord_num = this.order_num === '' ? 0 : this.order_num

      if (this.vehicleType == '101') {
        // 销售订单101  保存为 产品/客户
        this.clientId = this.varord_id === '' ? null : this.clientId
        this.productId = this.cloudId === '' ? null : this.cloudId
      } else if (this.vehicleType == '102') {
        // 采购订单102 保存为供应商/物料
        this.purveyId = this.clientId === '' ? null : this.clientId
        this.varord_id = this.cloudId === '' ? null : this.cloudId
      } else {
        // 倒料时去除客商
        this.clientId = null
      }

      console.log(this.userId)
      this.$http.post(`/ymt/vehicleorder/lubaoOrderSave`, {
        lubaoId: this.clientId,
        purveyId: this.purveyId,
        corp: this.tenantId,
        // materialId: this.varord_id,
        orderNumber: ord_num,
        orderSource: this.orderSource,
        orderType: this.orderType,
        lbValidDate: effDate,
        productId: this.varord_id,
        setDate: this.date_order,
        transporter: this.shippers_id,
        userType: this.cusTypes,
        isLongEffective: isLongEffective,
        vehicleList: this.carList,
        vehicleType: this.vehicleType,
        createUserId: this.cus_id,
        bigOrderId: this.orderObj.ordername
      }, {
        headers: { 'Authorization': 'Basic cGlnOnBpZw==' }
      }).then(res => {
        console.log(res)
        if (res.status == 200 && res.data.code == 0) {
          this.$dialog({
            message: res.data.msg == null ? '下单成功' : res.data.msg,
            theme: 'round-button'
          }).then(() => {
            this.btnLoading = false
            console.log('下单成功')
            // wx.miniProgram.navigateTo({
            //   url: '/pages/mediumOrder/mediumOrder?ordername=' + this.orderObj.ordername
            // })
            wx.miniProgram.navigateBack()
            sessionStorage.removeItem('carList')
          })
        } else {
          let err_msg = res.data.msg ? res.data.msg : '服务器出了点小差'
          this.btnLoading = false
          this.$dialog({
            message: err_msg,
            theme: 'round-button'
          }).then(() => {
            this.btnLoading = false
            console.log('下单失败')
          })
        }
      }).catch(err => {
        console.log(err)
        this.btnLoading = false
        this.$dialog({
          title: '提示',
          message: err.data.msg,
          confirmButtonColor: '#727AA1',
          showCancel: false
        }).then(() => {
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
.PlaceOrder {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: .1rem;
}

.van-cell {
  height: .5rem;
  line-height: .5rem;
  padding-left: .2rem;
  border-bottom: 1px solid #e1e1e1;
  font-size: .14rem;
  padding: 0 .16rem;
}

/deep/ .van-field__label {
  width: .88rem;
  margin-right: 0;
}

/deep/ .van-picker__cancel {
  font-size: .16rem;
}

/deep/ .van-picker__confirm {
  font-size: .16rem;
  color: #29afa5;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  padding: .1rem .16rem;
  font-size: .16rem;
  color: #969799;
  border-bottom: 1px solid #D7D6DC;
}

/deep/ .van-cell-group {
  height: 2rem;
  overflow: scroll;
  font-size: .16rem;
  text-align: center;
  border: none;
  border-top: 1px solid #D7D6DC;
  padding: .1rem 0;

  .van-cell {
    border-bottom: none;
    padding: 0;
    height: .45rem;
    line-height: .45rem;
    color: #999;
    // font-weight: 700;
  }
}

/deep/ .van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0;
}

/deep/ .van-search {
  width: 3.22rem;
}

/deep/ .van-search .van-cell {
  height: .4rem;
  line-height: .4rem;
  border-bottom: none;
  padding-left: 0;
}

/deep/ .van-search__content {
  background-color: #fff;
  border: .01rem solid #E6E6EA;
  border-radius: .2rem;
}

/deep/ .van-search .van-field__control {
  line-height: .4rem;
  font-size: .14rem;
}

/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  font-size: .16rem;
  color: #969799;
}

.search_btn {
  line-height: .62rem;
  font-size: .16rem;
  color: #29afa5;
}

/deep/ .van-field__control--custom {
  min-height: .5rem;
}

/deep/ .van-button--large {
  width: 95%;
  margin-top: .3rem;
  margin-bottom: .2rem;
  margin-right: .1rem;
  margin-left: .1rem;
  background: linear-gradient(to right, #4bb0ff, #6149f6);
  border-radius: .2rem;
  color: #fff;
}

/deep/ .van-field__button .van-button {
  width: .65rem;
  height: .32rem;
  font-size: .14rem;
  vertical-align: middle;
}

/deep/ .van-field__button .van-button:focus {
  background-color: #fff;
}

.car-box {
  width: 95%;
  height: 1.75rem;
  background-color: #fff;
  margin: .2rem auto 0;

  .car-head {
    padding: .1rem 0 0 .08rem;
    color: #646566;
  }

  .car-content {
    display: flex;
    padding: .1rem;
    height: 1.4rem;

    .car-list-box {
      width: 85%;
      height: 1.2rem;
      overflow: scroll;
      background-color: #f7f7f7;
    }

    .car-icon {
      width: 15%;
      font-size: .3rem;
      text-align: center;
      line-height: 1.4rem;
    }
  }

  .car-tag {
    width: 30%;
    // height: .4rem;
    // line-height: .4rem;
    display: inline-block;
    text-align: center;
    // background-color: #f2f2f2;
    color: #4bb0ff;
    margin: .1rem 0 0 .06rem;
  }
}
</style>
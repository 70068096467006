<template>
  <div class="OrdererInfo">
    <div v-wechat-title='$route.meta.title'></div>
    <van-field readonly  label="订单编号：" :value="form.code" />
    <van-field readonly  label="订单日期：" :value="form.setDate" />
    <van-field readonly  label="客商名称：" :value="form.clientName" />
    <van-field readonly  label="产品名称：" :value="form.materialName" />
    <van-field v-show="form.orderType == 2" readonly  label="有效日期：" :value="form.orderValidDate" />
    <van-field readonly  label="可接单数：" :value="form.vehicleNumber" />
    <van-field readonly  label="已接单数：" :value="form.detailCount" />
    <van-field readonly  label="订货数量" :value="form.orderNumber" />
    <van-field clickable required type="number"  label="装车数量：" v-model="driverObj.number" placeholder="请输入装车数量" />
    <van-field clickable readonly required label="车牌号码：" @click="showKeyword" v-model="driverObj.vehicle" placeholder="请输入车牌号码"/>
    <van-field clickable required label="司机姓名：" v-model="driverObj.driver" :onkeyup="driverObj.driver = driverObj.driver.replace(/\s+/g,'')" placeholder="请输入司机姓名"/>
    <van-field clickable required label="手机号码 ：" v-model="driverObj.phone" :onkeyup="driverObj.phone = driverObj.phone.replace(/\s+/g,'')" placeholder="请输入手机号码"/>
    <van-field clickable label="身份证号：" v-model="driverObj.idcard" :onkeyup="driverObj.idcard = driverObj.idcard.replace(/\s+/g,'')" placeholder="请输入身份证号"/>
    <van-button size="large" loading-text="加载中..." :loading="btnLoading" @click="takeOrders">接单</van-button>
    <!-- 车牌键盘 -->
    <keyword :isShow="keyState" @exit="exit" @inputchange="getKey" :oinp="str" @ok="keywordconfirm"/>
  </div>
</template>

<script>
import keyword from '../components/keyword'
import wx from 'weixin-js-sdk'
export default {
  name: 'DriverOrders',
  components: {
    keyword
  },
  data () {
    return {
      form: {},
      driverObj: {},
      tempOrderId: '',
      cus_id: '',
      btnLoading: false,
      // 车牌键盘的显示与隐藏
      keyState: false,
      // 车牌键盘输入的内容
      str: ''
    }
  },
  activated () {
    
  },
  created () {
    this.tempOrderId = this.$route.query.tempOrderId || ''
    this.cus_id = this.$route.query.cid || ''

    console.log(this.cus_id)
    this.getDriverOrders()
    // this.getInfoForCurUser()
  },
  methods: {
    async getDriverOrders () {
      const { data: res } = await this.$http.get('/ymt/vehicletemporder/' + this.tempOrderId)
      console.log(res)
      if (res.code == 0) {
        this.form = res.data.records[0] || {}
        console.log(this.driverObj, this.form)
        this.getInfoForCurUser()
      }
    },

    async getInfoForCurUser () {
      const { data: res } = await this.$http.get('/ymt/ymtdriver/getInfoForCurUser/' + this.cus_id)
      console.log(res)
      if (res.code == 0) {
        console.log(this.driverObj, this.form)
        this.$set(this.driverObj,'driver',res.data.driver || '')
        this.$set(this.driverObj,'vehicle',res.data.vehicle || '')
        this.$set(this.driverObj,'phone',res.data.phone || '')
        this.$set(this.driverObj,'idcard',res.data.idcard || '')
        
      }
    },

    showKeyword () {
      this.keyState = true
    },

    // 车牌键盘事件
    exit () {
      this.keyState = false
    },

    // 车牌号键盘输入的内容
    getKey (val) {
      console.log(this.str)
      if (this.str.length >= 8 && val !== 'delete') {
        return false
      }
      if (val === 'delete') {
        this.str = this.str.slice(0, this.str.length - 1)
        this.driverObj.vehicle = this.str
      } else {
        this.str += val
        this.driverObj.vehicle = this.str
      }
    },

    // 车牌号键盘点击确认
    keywordconfirm (e) {
      console.log(e)
      this.driverObj.vehicle = this.str
      this.keyState = false
    },

    async takeOrders () {
      const that = this
      const form =  Object.assign(that.form, that.driverObj);
      console.log(form)

      if (form.number == '' || form.number == null) {
        return that.$toast({ message: '请填写装车数量'})
      }


      if (form.vehicle == '' || form.vehicle == null) {
        return that.$toast({ message: '请填写车号'})
      }

      if (form.driver == '' || form.driver == null) {
        return that.$toast({ message: '请填写司机姓名'})
      }

      if (form.phone == '' || form.phone == null) {
        return that.$toast({ message: '请填写手机号'})
      }
      that.btnLoading = true
      const { data: res } = await that.$http.post('/ymt/driverorderdetail', {
        tempOrderId: form.id,
        driver: form.driver,
        phone: form.phone,
        vehicle: form.vehicle,
        number: form.number,
        idcard: form.idcard || ''
      })

      console.log(res)
      setTimeout(()=>{
        that.btnLoading = false
        if (res.code == 0) {
          this.$dialog({
            message: res.msg == null ? '接单成功' : res.msg,
            theme: 'round-button'
          }).then(() => {
            wx.miniProgram.switchTab({
              url: '/pages/index/index'
            })
          })
        } else {
          that.$toast({ message: res.msg})
        }
      }, 200)
      
      
    }
  }
}
</script>

<style lang="less" scoped>
.OrdererInfo {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: .1rem;
//   background-color: #f7f7f7;
}

.van-cell {
  height: .5rem;
  line-height: .5rem;
  padding-left: .2rem;
  border-bottom: 1px solid #e1e1e1;
  font-size: .14rem;
  padding: 0 .16rem;
}

/deep/ .van-field__label {
  width: .88rem;
  margin-right: 0;
//   text-align-last:justify;
//   text-align:justify;
//   text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
}

/deep/ .van-field__control--custom {
  min-height: .5rem;
}

/deep/ .van-button--large {
  width: 95%;
  margin-top: .5rem;
  margin-bottom: .2rem;
  margin-right: .1rem;
  margin-left: .1rem;
  background: linear-gradient(to right, #4bb0ff, #6149f6);
  border-radius: .2rem;
  color: #fff;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  padding: .1rem .16rem;
  font-size: .16rem;
  color: #969799;
  border-bottom: 1px solid #D7D6DC;
}

/deep/ .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
  font-size: .16rem;
  color: #969799;
}

/deep/ .van-cell-group {
  height: 2rem;
  overflow: scroll;
  font-size: .16rem;
  text-align: center;
  border: none;
  border-top: 1px solid #D7D6DC;
  padding: .1rem 0;
  .van-cell {
    border-bottom: none;
    padding: 0;
    height: .45rem;
    line-height: .45rem;
    color: #999;
    font-weight: 700;
  }
}

</style>